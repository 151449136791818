import React, {  } from 'react'
import { Loader } from 'semantic-ui-react'

export default function LoadingPage() {
  return (
    <div>
      <br />
      <br />
      <Loader active inline='centered'/>
    </div>
  )
}