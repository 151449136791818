import React, {useState, useEffect} from 'react'
import auth from './services/auth'
import { Redirect } from 'react-router-dom'

function EnsureAuthenticated(props) {

  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const isLoggedIn = async () => {
      const tempLoggedIn = await auth.isLoggedIn()
      if(!tempLoggedIn) {
        await setLoggedIn(false)
        await setLoading(false)
      }else {
        await setLoggedIn(true)
        await setLoading(false)
      }
    }
    isLoggedIn()
  }, []);

  if (!loggedIn && !loading) {
    return (
      <Redirect to={'/login'}/>
    )
  }
  return (
    <div>
      {!loading && props.children}
    </div>
  )
}

export default EnsureAuthenticated
