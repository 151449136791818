var config
var env = 'production'
// var env = 'production_ethikka'
// var env = 'development'

config = {
  production: {
    apiUrl: 'https://reportapi2.freespaceerp.com/api',
    authUrl: 'https://reportapi2.freespaceerp.com/auth'    
  },
  production_ethikka: {
    apiUrl: 'https://erpapi.ethikka.com/api',
    authUrl: 'https://erpapi.ethikka.com/auth'    
  },
  development: {
    apiUrl: 'http://localhost:8000/api',
    authUrl: 'http://localhost:8000/auth'
  }
};

export default config[env]