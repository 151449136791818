import React, { Suspense } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import './App.css'
import 'react-image-lightbox/style.css';
import AppContext from './AppContext'
import LoginPage from './pages/LoginPage'
import LoadingPage from './pages/LoadingPage'
import setupAxiosInterceptors from './services/axiosInterceptor';
import EnsureAuthenticated from './EnsureAuthenticated'
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'

const LoadableAdminContainer = React.lazy(() => import('./pages/AdminContainer'));

setupAxiosInterceptors(() => console.log('unauthorized'));

const appState = {
  // loggedAdmin: null,
  appVersion: '0.0.0',
  loggedAdmin: {
    _id: 1,
    username: 'Admin'
  }
}
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}

function App() {
  return (
    <AppContext.Provider value={appState}>
      <Router>
        <Switch>
          <Route exact path="/login" component={LoginPage} />
          <EnsureAuthenticated>
            <Route path="/" render={(props) => 
              <Suspense fallback={<LoadingPage />}>
                <AlertProvider template={AlertTemplate} {...options}>
                  <LoadableAdminContainer {...props}/>
                </AlertProvider>
              </Suspense>
            } />
          </EnsureAuthenticated>
        </Switch>
      </Router>
    </AppContext.Provider>
  );
}

export default App;
