import axios from 'axios'
import config from '../config'

const authUrl = `${config.authUrl}`

const setAuthorizationHeader = async () => {
  const token = await localStorage.getItem('fsadmintoken')
  if (token) {
    axios.defaults.headers.common['Authorization'] = `${token}`
  } else {
    delete axios.defaults.headers.common['Authorization']        
  }
  return
}

const login = async (email, password, secret) => {
  try {
    let result = await axios.post(`${authUrl}/admin-login`, {email, password, secret})
    result = result.data
    if(result && result.code === 'OK' && result.data.token) {
      await localStorage.setItem('fsadmintoken', result.data.token)
      await localStorage.setItem('user', JSON.stringify(result.data.data))
      await setAuthorizationHeader(result.data.token)
    } 
    return result
  }catch(error) {
    return error.response.data
  }
}

const generateSecret = async (email, password) => {
  try {
    let result = await axios.post(`${authUrl}/admin-get-secret`, {email, password})
    result = result.data
    return result
  }catch(error) {
    return error.response.data
  }
}

const logout = async () => {
  localStorage.clear()
  setAuthorizationHeader(false)
}

const isLoggedIn = async () => {
  const token = await localStorage.getItem('fsadmintoken')
  return token ? true : false
}

const getNewToken = async () => {
  await setAuthorizationHeader()
  let result = await axios.post(`${authUrl}/renew-token`)
  result = result.data
  if(result.code === 'OK' && result.data.token) {
    await localStorage.setItem('fsadmintoken', result.data.token)
    await localStorage.setItem('user', JSON.stringify(result.data.data))
    await setAuthorizationHeader(result.data.token)
    return
  } else {
    logout()
    return false
  }
}

export default {
  login,
  logout,
  isLoggedIn,
  setAuthorizationHeader,
  getNewToken,
  generateSecret
}