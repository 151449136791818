import React, { useState, useContext, useEffect } from 'react'
import { Button, Form, Grid, Message, Header, Image } from 'semantic-ui-react'
import queryString from 'query-string'

import AppContext from '../AppContext'
import auth from '../services/auth'

export default function LoginPage({history, location}) {

  const { appVersion } = useContext(AppContext);
  
  const [inputs, setInputs] = useState({})
  const [message, setMessage] = useState(null)

  useEffect(() => {
    const isLoggedIn = async () => {
      const isLoggedIn = await auth.isLoggedIn()
      if(isLoggedIn){
        history.push('/')
      }
    }
    isLoggedIn()    
  }, [history])
  
  const handleInputChange = (event) => {
    event.persist();
    setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
  }

  const handleSubmit = async (event) => {
    setMessage(null)
    if (event) {
      event.preventDefault();
    }
    if (!inputs.email || !inputs.password || !inputs.secret) {
      setMessage({type:'error', header: 'Incomplete', content: 'Please provide username, password and secret'})
      return
    }
    let result = await auth.login(inputs.email, inputs.password, inputs.secret)
    if (result && result.code === 'OK') {
      let data = queryString.parse(location.search)
      if (data.rdrUrl) {
        history.push(data.rdrUrl)
      } else {
        history.push('/')
      }
    } else {
      setMessage({type:'error', header: 'Login Failed', content: 'Bad request'})
    }
  }
  const generateSecret = async () => {
    setMessage(null)
    if (!inputs.email || !inputs.password) {
      setMessage({type:'error', header: 'Incomplete', content: 'Please provide username and password'})
      return
    }
    let result = await auth.generateSecret(inputs.email, inputs.password)
    if (result && result.code === 'OK') {
      setMessage({type:'sucess', header: 'Mailed', content: 'plz check email for key'})
    } else {
      setMessage({type:'error', header: 'Failed', content: 'Bad request'})
    }
  }


  return (
    <Grid textAlign='center'>
      <Grid.Column style={{ maxWidth: 360, marginTop: 100 }}>
        <Image src='/fslogo.svg' />
        <Header color='teal' textAlign='center'>
          Freespace Admin
        </Header>
        <br />
        <Form size='large' onSubmit={handleSubmit}>
          <Form.Input 
            name='email'
            fluid 
            size='mini'
            icon='user' 
            iconPosition='left' 
            placeholder='Email'
            onChange={handleInputChange}
          />
          <Form.Input
            name='password'
            fluid
            size='mini'
            icon='lock'
            iconPosition='left'
            placeholder='Password'
            type='password'
            onChange={handleInputChange}
          />
          <Form.Input
            name='secret'
            fluid
            size='mini'
            icon='lock'
            iconPosition='left'
            placeholder='Secret'
            type='password'
            action={{ 
              onClick: () => generateSecret(),
              labelPosition: 'right',
              icon: 'copy',
              content: 'Generate'
            }}
            
            onChange={handleInputChange}
          />

          <Button type='submit' color='teal' fluid size='mini'>
            Login
          </Button>
        </Form>
        <br/>
        <p><small>Version: {appVersion}</small></p>
        {
          message &&
          <Message
            success={message.type==='success'}
            error={message.type==='error'}
            header={message.header}
            content={message.content}
          />
        }
      </Grid.Column>
    </Grid>
  )
}